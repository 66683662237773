<template>
  <NuxtLayout>
    <NuxtLoadingIndicator />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
// Use SSR-safe IDs for Headless UI
const { region } = useStaticData();
const config = useRuntimeConfig();

provideHeadlessUseId(() => useId());
config.public.flyingkiwi.siteName = region.value?.attributes?.title ?? "";
</script>
